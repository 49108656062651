import GoBackButton from "../../resources/back-arrow.svg";
import { useCallback, useEffect, useState } from "react";
import { withContext } from "../../providers/UserContext";
import { withLanguage } from "../../providers/LanguageContext";
import "./styles.scss";
import { Link } from "react-router-dom";
import Env from "./../../.env.js";
import { Col, Container } from "react-bootstrap";
import { TrendingService } from "../../services/TrendingsService.js";
import {
  ShortIconSvg,
  MovieIconSvg,
  AudioIconSvg,
  PodcastIconSvg,
} from "../svg";
function Trending(props) {
  const { translate, currentLanguage, region } = props;
  const [trendingContents, setTrendingContents] = useState();
  const [numberSlide, setNumberSlide] = useState(5);

  useEffect(() => {
    const getTrending = async () => {
      try {
        const trendings = await TrendingService.getTrendings(region);
        setTrendingContents(trendings);
      } catch (e) {
        console.error("Error on load trending: ", e);
      }
    };
    getTrending();
  }, [region]);

  const getIconType = useCallback(({ contentType = "" }) => {
    switch (contentType.toLowerCase()) {
      case "audiobook":
        return AudioIconSvg;
      case "podcast":
        return PodcastIconSvg;
      case "movie":
        return MovieIconSvg;
      case "serie":
      case "short":
        return ShortIconSvg;

      default:
        return null;
    }
  }, []);

  const renderElement = (element, index) => {
    const contentType =
      element.contentType &&
      element.contentType !== "Audiobook" &&
      element.contentType !== "Podcast"
        ? element.contentType.toLowerCase()
        : "audio";

    const iconByType = getIconType({ contentType: element?.contentType });

    return (
      <Link
        key={index}
        id={`slide-${index}`}
        className="mr-3 border-black slide rounded-tr-3xl rounded-bl-3xl flex flex-col justify-between items-end  overflow-hidden hover:no-underline"
        to={`${contentType}/${element.contentId}`}
        style={{
          backgroundImage: `url("${element?.imageDPeque}")`,
          minWidth: "180px",
          minHeight: "170px",
          maxWidth: "180px",
          maxHeight: "170px",
          color: Env.env === "myst" ? "white" : "black",
        }}
      >
        <div
          className={`rounded-full p-2 m-2 ${
            Env.env === "myst" || Env.env === "glitch" ? "bg-app-primary" : ""
          } ${Env.env === "selego" ? "bg-app-secondary" : ""}`}
        >
          {iconByType}
        </div>

        <div
          className="self-start text-left p-3 w-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) , #000000 100%)",
          }}
        >
          <p className="title-3 truncate w-full">
            {element[`title${currentLanguage.toUpperCase()}`]}
          </p>
          <p className="title-4">{element.director1}</p>
        </div>
      </Link>
    );
  };

  if (trendingContents?.length === 0 || !trendingContents) {
    return <></>;
  }

  return (
    <div
      style={{
        backgroundImage: Env.env === "myst" && "url('/bg-trending.png')",
        backgroundSize: "100% 100%",
      }}
      className="bg-no-repeat bg-bottom"
    >
      <Container fluid="lg" className="no-padding-large">
        <Col className="py-10   col-container">
          <span className="title-1">{translate("mostViewed")}</span>
          <p
            className="title-2 "
            style={{ color: Env.env === "myst" && "white" }}
          >
            {translate("selectionForYou")}
          </p>
          <div className="flex mt-4 relative">
            {trendingContents?.length > 5 && (
              <a
                className="absolute  z-10 left-arrow px-2 h-full flex items-center"
                onClick={() =>
                  setNumberSlide((prevNumber) =>
                    prevNumber - 5 > 0 ? prevNumber - 5 : 0
                  )
                }
                href={`#slide-${numberSlide}`}
              >
                <img src={GoBackButton} alt="arrow" />
              </a>
            )}
            <div className="flex  overflow-x-scroll slides">
              {trendingContents?.map(renderElement)}
            </div>
            {trendingContents?.length > 5 && (
              <a
                className="absolute  right-arrow px-2 h-full flex items-center"
                onClick={() =>
                  setNumberSlide((prevNumber) =>
                    prevNumber + 5 < trendingContents.length
                      ? prevNumber + 5
                      : trendingContents.length - 1
                  )
                }
                href={`#slide-${numberSlide}`}
              >
                <img src={GoBackButton} alt="arrow" />
              </a>
            )}
          </div>
        </Col>
      </Container>
    </div>
  );
}

export default withContext(withLanguage(Trending));
