import classNames from "classnames";
import { useState } from "react";
import { withContext } from "../../providers/UserContext";

import Env from "../../.env.js";
const user_reasons = {content: 1, price: 2, technical_issues: 3, product: 4, too_many_services: 5, other: 6}

function CancelSubForm({ translate, uid, ...props }) {
  const [isChecked, setIsChecked] = useState({});
  const [otherText, setOtherText] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({
      user_cancel_reason: user_reasons[isChecked] ?? '',
      custom_cancel_reason: otherText ?? "",
    });
  };

  const textColor = Env.env === "glitch" || Env.env === "myst"  ? "text-white" : "text-black";

  const renderCheckboxOption = (translation, key) => (
    <div className="py-2 my-2 flex items-center">
      <div
        className={classNames(
          "mx-2 border-2 primary-border-color w-4 h-4 rounded",
          {
            "primary-background-color": isChecked === key,
          }
        )}
        onClick={() =>
          setIsChecked(() => {
            return key;
          })
        }
      />
      <span className={textColor}>{translate(translation)}</span>
    </div>
  );

  return (
    <>
      <h1 className={`${textColor} font-bold my-4`}>{translate("noo!")}</h1>
      <p className={textColor}>{translate("cancelSubStep1Msg")}</p>
      <form className="my-4" onSubmit={onSubmit}>
        <h5 className="primary-color font-bold">
          {translate("cancelFormTitle")}
        </h5>
        {Object.keys(user_reasons).map((reason, i) => {

          return renderCheckboxOption(`cancelSubOption${i}`, reason);
        })}
        <label className={`${textColor} capitalize block`}>
          {translate("other")}:
        </label>
        <div className="lg:flex w-full justify-between items-end lg:pr-24 mb-3">
          <textarea
            className="rounded opacity-50 p-3 w-8/12"
            placeholder={translate("cancelReason")}
            rows="5"
            value={otherText}
            onChange={(e) => setOtherText(e.target.value)}
          />
          <button
            className={`${
              Env.env === "glitch" ? "" : "text-white"
            } primary-background-color px-5 py-2 tracking-widest`}
            type="submit"
          >
            {translate("next")}
          </button>
        </div>
      </form>
    </>
  );
}

export default withContext(CancelSubForm);
