import React, { useEffect, useState } from "react";
import OfferModal from "../../tribes-media-ui/components/OfferModal";
import { useHistory, useLocation } from "react-router";
import { withContext } from "../../providers/UserContext";
import { withModalsContext } from "../../providers/ModalsContext";
import { withLanguage } from "../../providers/LanguageContext";
import "./styles.css";

function Offer(props) {
  const { search, hash } = useLocation();
  const {
    translate,
    onToggle,
    openOffer,
  } = props;
  const [open, setOpen] = useState(
    search?.split("=")[0].includes("offer") && !hash
  );
  const history = useHistory();

  useEffect(() => {
    if (!open) setOpen(openOffer);
  }, [openOffer, open]);

  return (
    <OfferModal
      open={open}
      labels={{
        title: translate("offerTitle"),
        bodyPart1: translate("offerBodyPart1"),
        bodyPart2: translate("offerBodyPart2"),
        button: translate("offerBtn"),
        promo: translate("offerPromo"),
        secondLine: translate("offerSecondLine"),
        subtitle: translate("offerSubtitle"),
        imgSvg: translate("offerLabel"),
      }}
      buttonClassName="buttonClassName"
      onClose={() => {
        setOpen(false);
        history.push(`/`);
      }}
      onClick={() => {
        onToggle("openSignup");
        setOpen(false);
      }}
    />
  );
}

export default withLanguage(withModalsContext(withContext(Offer)));
