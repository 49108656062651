import React, { useState, useEffect } from "react";

interface CountdownTimerProps {
  targetDate: string;
  translate: any;
  timerColor?: string;
  textColor?: string;
}

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;

}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  targetDate,
  translate,
  timerColor,
  textColor,
  children
}) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = new Date(targetDate).getTime() - new Date().getTime();
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval as keyof TimeLeft]) {
      return;
    }
    
    timerComponents.push(
      <div
        key={interval}
        className="flex flex-col items-center  m-1 md:m-2 rounded-lg"
        style={{ color: timerColor }}
      >
        <span className="text-base md:text-2xl font-bold">
          {timeLeft[interval as keyof TimeLeft]}
        </span>
        <span className="text-xs md:text-base" style={{ color: textColor }} >
          {translate(interval)}
        </span>
      </div>
    );
  });

  return (
   <>
     <div className="flex justify-center mb-4">
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
    {timerComponents.length ? children : null}
   </>
  );
};

export default CountdownTimer;
