import React from "react";
import { Row, Col } from "react-bootstrap";
import { withLanguage } from "../../providers/LanguageContext";
import { withContext } from "../../providers/UserContext";
import ReadMore from "../ReadMore";
import Env from "./../../.env.js";
import "./styles.scss";
class Author extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorialEN: "",
      editorialES: "",
    };
  }

  componentDidMount() {
    this.setState({
      editorialEN: this.props.editorial.editorialEN,
      editorialES: this.props.editorial.editorialES,
    });
  }

  render() {
    const selego = Env.env === "selego";
    return (
      <div className={`author-detail`}>
        <Row className="row-author">
          <Col className="col-content">
            <div className={`title-author ${selego && "selego-text"}`}>
              {this.props.translate("aboutAuthors")}
            </div>
            <div className={`content-author ${selego && "selego-text"}`}>
              <ReadMore
                text={
                  this.props.currentLanguage === "en"
                    ? this.state.editorialEN
                    : this.state.editorialES
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withLanguage(withContext(Author));
