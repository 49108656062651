import React, { Component, createContext } from "react";
import UserServices from "../services/UserServices";
import LanguageServices from "../services/LanguageServices";
import { UserProvider } from "./UserContext";
import { getNavigatorLanguage } from "../utils";

export const UserContext = createContext({
  user: null,
  currentLanguage: "en",
  translate: null,
});

const navigator_lang = getNavigatorLanguage();

class AuthProvider extends Component {
  state = {
    user: {
      auth: null,
      data: null,
      token: null,
    },
    currentLanguage: "en",
    data: null,
  };

  componentDidMount = async () => {
    try {
      const [lanData] = await Promise.all([LanguageServices.getData()]);

      const userAuth = JSON.parse(localStorage.getItem("userAuth"));
      if (userAuth) {
        let currentUser = this.state.user;
        currentUser.auth = {
          ...userAuth,
          token: `${userAuth.token}`,
        };
        currentUser.data = userAuth.resource_owner;
      }
      this.setState({
        data: lanData,
      });
      this.loadUserData();
    } catch (e) {
      console.log(e);
    }
  };

  setUserAuth = (userAuth) => {
    localStorage.setItem("userAuth", JSON.stringify(userAuth));
    let currentUser = this.state.user;
    currentUser.auth = {
      ...userAuth,
      token: `${userAuth.token}`,
    };
    currentUser.data = userAuth.resource_owner;
  };

  setUserData = (userData) => {
    let currentUser = this.state.user;
    currentUser.data = userData;
  };

  loadUserData = async () => {
    const localLang = localStorage.getItem("_lang");

    if (this.state.user.auth !== null) {
      try {
        const userInfo = await UserServices.getUser(
          this.state.user.auth.token,
          this.state.user?.data?.id
        );

        const langStored = userInfo.language || localLang || navigator_lang || "en"; //default_user_language || localLang || navigatorLang || "en";

        this.setState({
          user: {
            ...this.state.user,
            data: userInfo,
          },
          currentLanguage: langStored,
        });
      } catch (e) {
        let currentUser = this.state.user;
        this.setState({
          user: currentUser,
        });
      }
    } else {
      const langAnonymous = localLang || navigator_lang || "en";

      this.setState({
        user: {
          auth: null,
          data: null,
          plan: null,
        },
        currentLanguage: langAnonymous,
      });
    }

  };

  translate = (index) => {
    if (this.state.data !== null) {
      return this.state.data?.[index]?.[
        this.state.currentLanguage.toLowerCase()
      ];
    }
  };

  switchLanguage = (code) => {
    if (
      this.state?.user?.data?.id &&
      this.state?.user?.data?.language !== code
    ) {
      UserServices.editUser(
        {
          userId: this.state?.user?.data?.id,
          language: code,
        },
        this.state.user.auth.token
      );
    }

    this.setState((state) => ({
      currentLanguage: code,
      user: {
        ...this.state.user,
        data: { ...state.user.data, language: code },
      },
    }));
  };

  cleanUserData = () => {
    localStorage.removeItem("userAuth");
    this.setState({
      user: {
        auth: null,
        data: null,
        plan: undefined,
        token: null,
      },
      currentLanguage: navigator_lang,
    });
  };

  render() {
    return (
      <UserProvider
        value={{
          user: this.state.user,
          loadUserData: this.loadUserData,
          currentLanguage: this.state.currentLanguage,
          translate: this.translate,
          switchLanguage: this.switchLanguage,
          setUserAuth: this.setUserAuth,
          cleanUserData: this.cleanUserData,
          setUserData: this.setUserData,
        }}
      >
        {this.props.children}
      </UserProvider>
    );
  }
}

export default AuthProvider;
