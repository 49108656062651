import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { EditSubModal, BasicModal } from "../tribes-media-ui/components";
import { withLanguage } from "../providers/LanguageContext";
import { useModals } from "../providers/ModalsContext";
import { withNotification } from "../providers/NotificationContext";
import { withContext } from "../providers/UserContext";
import UserServices from "../services/UserServices";
import { Logo } from "./../resources/Logos";
import Env from "./../.env.js";
import { logScreenView } from "../utils/analyticsEvents";

function EditSub(props) {
  const { translate, user, currentLanguage, loadUserData, countryPlans } =
    props;
  const [loading, setLoading] = React.useState(false);
  const [messages, setMessages] = React.useState({ success: "", error: "" });
  const [openCancel, setOpenCancel] = React.useState(false);
  const history = useHistory();
  const { openEditSubscription, onToggle } = useModals();
  const planSelected = user.data
    ? {
        interval: user.data.subscription_period,
      }
    : undefined;

  const onClose = useCallback(() => {
    if (history.location?.hash === "#openEditSubscription") {
      history.replace(history.location.pathname);
    }
    onToggle("openEditSubscription");
  }, [history, onToggle]);

  React.useEffect(() => {
    if (openEditSubscription) {
      logScreenView({
        name: "My Subscription View",
      });
    }
  }, [openEditSubscription]);

  async function onSubmit(planSelected, token) {
    setLoading(true);
    try {
      await UserServices.subscribe(planSelected, token);
      setLoading(false);
      onToggle("openEditSubscription");
      loadUserData();
      history.push("/success");
    } catch (e) {
      setLoading(false);
      setMessages({
        error: translate("errorMsg"),
        success: "",
      });
    }
  }

  function getSubscriptionLabel() {
    const label = `${translate(`${user.data?.last_subscription?.renewal_period?.toLowerCase() || ""}_plan`)}`;
    return label?.toUpperCase() || '';
  }

  async function onCancel() {
    onClose();
    history.push("/cancel-subscription");
  }

  function onCloseCancel() {
    setOpenCancel(false);
    onToggle("openEditSubscription");
  }

  return (
    <>
      <EditSubModal
        open={openEditSubscription}
        onClose={onClose}
        onSubmit={onSubmit}
        apiKey={Env.stripe_api_key}
        language={currentLanguage}
        planSelected={planSelected}
        disabled={loading}
        errorMessage={messages.error}
        successMessage={messages.success}
        color="primary"
        logo={Logo}
        onCancel={onCancel}
        openPlans={() => {
          onToggle("openSignup", true);
        }}
        plans={countryPlans}
        list={[
          translate("subPromo1"),
          translate("subPromo2"),
          translate("subPromo3"),
          translate("subPromo4"),
        ]}
        labels={{
          title: translate("bannerTitle"),
          save: translate("saveBtn"),
          subscription: getSubscriptionLabel(),
          cancel: translate("cancelSubMsg"),
          cardNumber: translate("cardNumber"),
          discount:
            planSelected?.interval === "yearly"
              ? translate("saveAnually")
              : undefined,
          cardCVC: translate("cardCVC"),
          cardExpiration: translate("cardExpiration"),
          confirm: translate("confirmBtn"),
          unavailable:
            planSelected?.interval === "yearly"
              ? translate("alreadySubscribed")
              : undefined,
          plansSubTitle: translate("startSubscriptionTitle"),
          yesAgree: currentLanguage === "es" ? "Desuscribir" : "Unsubscribe",
          plansDetails: (
            <p
              dangerouslySetInnerHTML={{
                __html: translate("startSubscriptionSubtitle"),
              }}
            />
          ),
          termsAndConditions: (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cdn3.themyst.com/TermsandConditions.html"
              >
                {translate("termsAndUse")}
              </a>
              {" & "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cdn3.themyst.com/privacy.html"
              >
                {translate("privacyPolicy")}
              </a>
            </>
          ),
        }}
      />
      <BasicModal
        title={translate("cancelSubApple")}
        onClose={onCloseCancel}
        logo={Logo}
        open={openCancel}
      />
    </>
  );
}

export default withNotification(withLanguage(withContext(EditSub)));
