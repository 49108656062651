import { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { withLanguage } from "../providers/LanguageContext";
import { withContext } from "../providers/UserContext";
import MovieServices from "../services/MovieServices";
import SerieServices from "../services/SerieServices";
import Env from "./../.env.js";

function ContinueWatching({
  actualItem,
  region,
  currentLanguage,
  translate,
  setOpen,
  id,
  episode,
}) {
  const [newItem, setNewItem] = useState();
  const [seconds, setSeconds] = useState();
  const glitch = Env.env === "glitch";
  const history = useHistory();

  const getLink = useCallback(() => {
    if (newItem.data.contentType === "Movie") {
      return `/player/movie/${newItem.id}`;
    } else {
      return glitch
        ? `/player/short/${newItem.id}`
        : `/player/serie/${newItem.id}/season/${newItem.season}/episode/${newItem.episode}`;
    }
  }, [newItem, glitch]);

  const onPlay = useCallback(() => {
    history.push(getLink());
    setOpen(false);
  }, [getLink, history, setOpen]);

  useEffect(() => {
    if (actualItem.contentType === "Movie") {
      MovieServices.getMovies(null, region).then((data) => {
        const { movies } = data;
        const pNewItem = movies.filter(
          (element) => element.data.titleEN !== actualItem.titleEN
        )[Math.floor(Math.random() * (movies.length - 1))];
        setNewItem(pNewItem);
        setSeconds(5);
      });
    } else if (episode) {
      const { seasons } = actualItem;
      const season = seasons.find((pSeas) =>
        pSeas.episodes.find(
          (pEpisode) => pEpisode.contentId === episode.contentId
        )
      );
      let nextEpisode = season.episodes.find(
        (pEpisode) => pEpisode.number === episode.number + 1
      );
      const nextSeason = seasons.find(
        (pSeason) => pSeason.number === season.number + 1
      );
      if (nextEpisode) {
        setNewItem({
          id,
          season: season.number,
          episode: nextEpisode.number,
          data: {
            imageDPeque: nextEpisode.imageMobile,
            titleEN: nextEpisode.titleEN,
            titleES: nextEpisode.titleES,
            descriptionEN: actualItem.descriptionEN,
            descriptionES: actualItem.descriptionES,
          },
        });
        setSeconds(5);
      } else if (nextSeason) {
        nextEpisode = nextSeason.episodes.find(
          (pEpisode) => pEpisode.number === 1
        );
        setNewItem({
          id,
          season: 1,
          episode: nextEpisode.number,
          data: {
            imageDPeque: nextEpisode.imageMobile,
            titleEN: nextEpisode.titleEN,
            titleES: nextEpisode.titleES,
            descriptionEN: actualItem.descriptionEN,
            descriptionES: actualItem.descriptionES,
          },
        });
        setSeconds(5);
      } else {
        SerieServices.getSeries(null, region).then((data) => {
          const { series } = data;
          const pNewItem = series.filter((element) => element.id !== id)[
            Math.floor(Math.random() * (series.length - 1))
          ];
          nextEpisode = pNewItem.data.seasons
            .find((pSeas) => pSeas.number === 1)
            .episodes.find((pEpi) => pEpi.number === 1);
          setNewItem({
            id: pNewItem.id,
            season: 1,
            episode: nextEpisode.number,
            data: {
              imageDPeque: nextEpisode.imageMobile,
              titleEN: nextEpisode.titleEN,
              titleES: nextEpisode.titleES,
              descriptionEN: pNewItem.data.descriptionEN,
              descriptionES: pNewItem.data.descriptionES,
            },
          });
          setSeconds(5);
        });
      }
    }
  }, [actualItem, episode, id, region]);

  useEffect(() => {
    if (seconds >= 0) {
      if (seconds !== 0)
        setTimeout(() => setSeconds((prevState) => prevState - 1), 1000);
      else onPlay();
    }
  }, [seconds, onPlay]);

  return newItem ? (
    <div
      className="absolute bottom-0 right-0 p-3 m-2 rounded flex w-5/12"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="w-6/12">
        <img src={newItem.data.imageDPeque} alt={newItem.id} />
      </div>
      <div className="mx-3 w-5/12 flex flex-col">
        <h2 className="text-white font-bold">
          {newItem.data[`title${currentLanguage.toUpperCase()}`]}
        </h2>
        <p className="text-white break-words h-full text-xs">
          {newItem.data[
            `description${currentLanguage.toUpperCase()}`
          ].substring(0, 100)}
          ...
        </p>
        <button
          className="primary-background-color text-white font-bold py-1"
          onClick={() => onPlay()}
        >
          {translate("play")}
          {"  "}
          {seconds}
        </button>
      </div>
    </div>
  ) : (
    <Fragment />
  );
}

export default withLanguage(withContext(ContinueWatching));
