import axios, { AxiosResponse } from "axios";
import Env from "../../src/.env.js";
import {
  EXPIRED_TOKEN,
  INVALID_TOKEN,
  EXPIRED_REFRESH_TOKEN,
} from "../models/constants/LoginErrors";
import { AuthServices } from "../services/AuthServices.ts";

const handleAuthErrors = async (error = "") => {
  switch (error) {
    case EXPIRED_TOKEN:
      try {
        await refreshToken();
      } catch (error) {
        console.log("🚀 ~ handleAuthErrors ~ refreshToken error:", error);
      }
      break;
    case INVALID_TOKEN:
    case EXPIRED_REFRESH_TOKEN:
      localStorage.removeItem("userAuth");
      window.location.href = "/";
      break;
    default:
      return error;
  }
};
export const refreshToken = async () => {
  const user = JSON.parse(localStorage.getItem("userAuth"));

  try {
    const refreshToken = user?.refresh_token;

    if (user && refreshToken) {
      const refreshRes = await AuthServices.refreshToken(refreshToken);
      const data = refreshRes?.data;

      localStorage.setItem("userAuth", JSON.stringify(data));
      window.location.href = "/";
    }
  } catch (error) {
    handleAuthErrors(error?.response?.data?.error);
  }
};

export const genericGet = (
  query: string,
  region?: string,
  params?: any,
  token?: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .get(Env.api_url + query + (region ? `&country_code=${region}` : ""), {
        params: params ? params : undefined,
        headers: {
          Authorization: token ? `Bearer ${token ?? ""}` : "",
        },
      })
      .then((response) => {
        if (response && response.data) {
          resolve(response);
        } else {
          reject();
        }
      })
      .catch((error) => {
        handleAuthErrors(error.response?.data?.error);
        reject(error);
      });
  });
};

export const genericPost = (
  query: string,
  region?: string,
  params?: any,
  token?: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .post(
        Env.api_url + query + (region ? `&country_code=${region}` : ""),
        params ? params : undefined,
        {
          headers: {
            Authorization: token ? `Bearer ${token ?? ""}` : "",
          },
        }
      )
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          //204 data without content
          if (response?.data !== undefined || response?.status === 204) {
            resolve(response);
          } else {
            reject(new Error("Response without content"));
          }
        } else {
          reject(new Error("Error in the response"));
        }
      })
      .catch((error) => {
        handleAuthErrors(error.response?.data?.error);
        reject(error);
      });
  });
};

export const genericPatch = (
  query: string,
  region?: string,
  params?: any,
  token?: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .patch(
        Env.api_url + query + (region ? `&country_code=${region}` : ""),
        params ? params : undefined,
        {
          headers: {
            Authorization: token ? `Bearer ${token ?? ""}` : "",
          },
        }
      )
      .then((response) => {
        if (response && response.data) {
          resolve(response);
        } else {
          reject();
        }
      })
      .catch((error) => {
        handleAuthErrors(error.response?.data?.error);
        reject(error);
      });
  });
};

export const genericDelete = ({
  query,
  params = false,
  token,
}: {
  query: string;
  params: any;
  token: string;
}) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .delete(`${Env.api_url}${query}`, {
        params: params ? params : undefined,
        headers: {
          Authorization: token ? `Bearer ${token ?? ""}` : "",
        },
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response && response.data) {
          resolve(response);
        } else {
          reject();
        }
      })
      .catch((error) => {
        handleAuthErrors(error.response?.data?.error);

        reject(error);
      });
  });
};
