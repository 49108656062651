import React, { useContext, useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { EditAccountModal, LoginModal } from "../tribes-media-ui/components";
import { withLanguage } from "../providers/LanguageContext";
import { useModals } from "../providers/ModalsContext";
import { withNotification } from "../providers/NotificationContext";
import UserContext, { withContext } from "../providers/UserContext";
import { auth } from "../services/FirebaseService";
import UserServices from "../services/UserServices";
import { Logo } from "./../resources/Logos";

function EditAccount(props) {
  const { translate, user, currentLanguage, runNotification } = props;
  const { openEditAccount, onToggle } = useModals();
  const [loading, setLoading] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const { setUserData } = useContext(UserContext);
  const values = useRef(null);

  const onSubmit = async (form) => {
    try {
      const userUpdated = await UserServices.editUser(
        {
          userId: user.data.id,
          lastName: form.lastName,
          name: form.name,
        },
        user.auth.token
      );
      setUserData(userUpdated.data.user);
      onToggle("openEditAccount");
    } catch (e) {
      runNotification(e, "error");
    }
  };

  async function onLogin(form) {
    setLoading(true);
    try {
      await UserServices.login(form.email, form.password);
      const isNewEmail = values.current.email !== user.auth.email;
      if (isNewEmail) {
        await auth.currentUser.updateEmail(values.current.email);
      }
      if (values.current.password > 0) {
        await UserServices.login(
          isNewEmail ? values.current.email : form.email,
          form.password
        );
        await auth.currentUser.updatePassword(values.current.password);
      }
      setLoading(false);
      setOpenLogin(false);
      onToggle("openEditAccount");
      toast.success(translate("successMsg"));
    } catch (e) {
      console.log(e);
      setLoading(false);
      runNotification(e, "error");
    }
  }

  function getSubscriptionLabel() {
    const label = translate(
      `${user?.data?.subscription_period?.toLowerCase()}_plan`
    );
    return label?.toUpperCase() || '';
  }
  return (
    <>
      {user?.auth !== null && (
        <EditAccountModal
          open={openEditAccount}
          onClose={() => onToggle("openEditAccount")}
          email={user?.data?.email}
          name={user?.data?.first_name}
          lastName={user?.data?.last_name}
          userId={user?.data?.id}
          disabled={loading}
          labels={{
            title: translate("bannerTitle"),
            save: translate("saveBtn"),
            subscription: user?.data?.active ? getSubscriptionLabel() : null,
            email: translate("email"),
            password: translate("password"),
            name: translate("name"),
            lastName: translate("lastName"),
          }}
          language={currentLanguage?.toLowerCase()}
          onSubmit={onSubmit}
          logo={Logo}
          color="secondary"
        />
      )}
      <LoginModal
        open={openLogin}
        onClose={() => setOpenLogin(false)}
        title={translate("bannerTitle")}
        loginLabel={translate("login")}
        language={currentLanguage.toLowerCase()}
        disabled={loading}
        onSubmit={onLogin}
        color="secondary"
        logo={Logo}
      />
    </>
  );
}

export default withNotification(withLanguage(withContext(EditAccount)));
