import { useState } from "react";
import { Logo } from "../../resources/Logos";
import { AuthServices } from "../../services/AuthServices";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import { withLanguage } from "../../providers/LanguageContext";
import { useFormik } from "formik";
import { validate } from "../../tribes-media-ui/components/FlowSubs/pieces/utils";
import Input from "../../tribes-media-ui/components/Input";
import isEmpty from "lodash/isEmpty";
import { withContext } from "../../providers/UserContext";

const ResetPasswordScreen = (props: any) => {
  const { currentLanguage, translate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [error, seterror] = useState();

  //Get params from url
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get("reset_password_token");
  const email = queryParams.get("email");
  console.log("email", email);

  const formik = useFormik({
    initialValues: { password: "", repeatPassword: "" },
    validate: validate(currentLanguage, true),
    onSubmit: async (values) => {
      if (resetToken && email) {
        setIsLoading(true);
        try {
          await AuthServices.resetPassword(
            resetToken,
            email,
            values.password,
            values.repeatPassword
          );
          setIsLoading(false);
          setIsReseted(true);
        } catch (e: any) {
          seterror(e);
          setIsReseted(true);
          setIsLoading(false);
          console.error(e);
        }
      }
    },
  });
  return (
    <div className="flex justify-center items-center flex-col m-5">
      <img src={Logo} alt="Logo" className="d-none d-md-block w-48 m-3" />
      {!isReseted && !isLoading && (
        <form onSubmit={formik.handleSubmit} className="w-full sm:w-3/5 mt-12">
          <Input
            placeholder={currentLanguage === "en" ? "password" : "contraseña"}
            containerClassname="mb-10"
            className={`w-full`}
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
          />
          <Input
            placeholder={
              currentLanguage === "en"
                ? "repeat password"
                : "repetir contraseña"
            }
            containerClassname="mb-10"
            className={`w-full`}
            id="repeatPassword"
            name="repeatPassword"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.repeatPassword}
            error={
              formik.touched.repeatPassword && formik.errors.repeatPassword
            }
          />

          <button
            className="primary-background-color text-white py-2 px-6 w-full uppercase disabled:opacity-10"
            type="submit"
            disabled={!isEmpty(formik.errors) || !formik.dirty}
          >
            {currentLanguage === "en"
              ? "Reset password"
              : "Resetear contraseña"}
          </button>
        </form>
      )}

      {isLoading ? (
        <Loading show />
      ) : isReseted ? (
        <>
          {!error ? (
            <div className="flex flex-col items-center justify-center">
              <span className="text-white mb-4 text-2xl">
                {translate("resetPasswordSuccess")}              
              </span>
              <button className="primary-background-color text-white font-bold py-2 px-6 mb-4">
                <Link to="/" className="text-white no-underline">
                  {currentLanguage && currentLanguage === "es"
                    ? "Inicio"
                    : "Go home"}
                </Link>
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <span className="text-white mb-4 text-2xl">{`Error: Token is invalid`}</span>
              <button className="primary-background-color text-white font-bold py-2 px-6 mb-4">
                <Link to="/" className="text-white no-underline">
                  {currentLanguage && currentLanguage === "es"
                    ? "Inicio"
                    : "Go home"}
                </Link>
              </button>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withContext(withLanguage(ResetPasswordScreen));
