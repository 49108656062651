import React from "react";
import { Player, PlayerEvent } from "bitmovin-player";
import UserServices from "../../services/UserServices";
import TagManager from "react-gtm-module";
import MovieServices from "../../services/MovieServices";
import SerieServices from "../../services/SerieServices";
import AudioServices from "../../services/AudioServices";
import GoBackButton from "../../resources/back-arrow.svg";
import { toast } from "react-toastify";
import { withLanguage } from "../../providers/LanguageContext";
import Env from "../../.env";
import "./styles.scss";
import ContinueWatching from "../../components/ContinueWatching.jsx";
import { logContentViewed, logCustomEvent } from "../../utils/analyticsEvents";
import { withContext } from "../../providers/UserContext";

class PlayerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      item: {},
      episode: null,
      usingSlot: false,
      continueWatching: false,
      playerConfig: {
        key: Env.bitmovin_license,
        remotecontrol: {
          type: "googlecast",
        },
        location: {
          ui:
            window.location.origin +
            `/bitmovin-${Env.env}/bitmovinplayer-ui.min.js`,
          ui_css:
            window.location.origin +
            `/bitmovin-${Env.env}/bitmovinplayer-ui.min.css`,
        },
      },
      playerSource: null,
      minutesCounter: 0,
      uid: props.user.data.uid,
    };
  }

  componentDidMount() {
    const uid = this.props.user.data.uid;
    this.setState({ uid });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.id !== prevProps.match.params.id ||
      this.props.match.params.episode !== prevProps.match.params.episode ||
      !this.state.item.titleEN
    ) {
      if (this.props.region === "XY" && !this.props.isServiceEnabled) {
        return this.props.history.push("/unavailable");
      }
      try {
        if (
          (Env.env === "glitch" && this.props.match.path.includes("short")) ||
          this.props.match.params.episode !== undefined
        ) {
          const serieResponse = await SerieServices.getSerie(
            this.props.match.params.id
          );
          const serie = serieResponse.data;

          if (
            Env.env !== "glitch" &&
            this.props.match.params.episode !== undefined
          ) {
            for (const season of serie.seasons) {
              if (season.number === +this.props.match.params.season) {
                for (const episode of season.episodes) {
                  if (episode.number === +this.props.match.params.episode) {
                    this.setState(
                      {
                        item: serie,
                        episode,
                        playerSource: { hls: episode.manifestURL },
                      },
                      () => {
                        this.setupPlayer();
                      }
                    );
                  }
                }
              }
            }
          } else if (
            Env.env === "glitch" &&
            this.props.match.path.includes("short")
          ) {
            this.setState(
              { item: serie, playerSource: { hls: serie.manifestURL } },
              () => {
                this.setupPlayer();
              }
            );
          }
        } else if (this.props.match.params.chapter !== undefined) {
          const audio = await AudioServices.getAudio(
            this.props.match.params.id
          );
          for (const part of audio.parts) {
            if (part.number === +this.props.match.params.part) {
              for (const chapter of part.chapters) {
                if (chapter.number === this.props.match.params.chapter) {
                  this.setState(
                    {
                      item: audio,
                      episode: chapter,
                      playerSource: { hls: chapter.manifestURL },
                    },
                    () => {
                      this.setupPlayer();
                    }
                  );
                }
              }
            }
          }
        } else {
          const movieResponse = await MovieServices.getMovie(
            this.props.match.params.id
          );
          const movie = movieResponse.data;
          this.setState(
            { item: movie, playerSource: { hls: movie.manifestURL } },
            () => {
              this.setupPlayer();
            }
          );
        }
      } catch (err) {
        this.props.history.push("/404");
      }
    }
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  async setupPlayer() {
    try {
      const player = new Player(
        document.getElementById("player"),
        this.state.playerConfig
      );
      UserServices.getLog(this.props.match.params.id).then((log) => {
        player
          .load({
            ...this.state.playerSource,
          })
          .then(
            () => {
              this.setState({ ...this.state, player });
              if (log !== null) {
                player.play().then(() => player.seek(log.data.time));
              }
              let firstTitlePlayEvent = false;
              const tracked = [];
              let titlePlayViewed;
              player.on(PlayerEvent.TimeChanged, (event) => {
                const duration = player.getDuration();
                let currentTime = event.time;
                let currentTimeRouded = Math.round(currentTime);
                const durations = [
                  Math.floor(duration * 0.1),
                  Math.floor(duration * 0.8),
                ];
                const baseParams = {
                  contentTitle: this.state.episode
                    ? this.state.episode.titleEN
                    : this.state.item.titleEN,
                  contentId: this.state.episode
                    ? this.state.episode.contentId
                    : this.state.item.contentId,
                };
                if (Math.floor(duration - currentTime) === 15)
                  this.setState({ ...this.state, continueWatching: true });

                durations.forEach((durationEvent) => {
                  const durationCondition =
                    durationEvent === currentTimeRouded &&
                    !tracked.includes(currentTimeRouded);
                  if (durationCondition || !firstTitlePlayEvent) {
                    tracked.push(currentTimeRouded);

                    logCustomEvent({
                      name: "title_play",
                      params: {
                        ...baseParams,
                        contentType: this.state.episode
                          ? this.state.episode.contentType
                          : this.state.item.contentType,
                        viewed: firstTitlePlayEvent
                          ? Math.round((durationEvent / duration) * 100)
                          : 0,
                        contentLength: duration,
                        director: `${this.state.item.director1}${
                          this.state.item.director2
                            ? `, ${this.state.item.director2}`
                            : ""
                        }${
                          this.state.item.director3
                            ? `, ${this.state.item.director3}`
                            : ""
                        }`,
                      },
                    });

                    if (!firstTitlePlayEvent) firstTitlePlayEvent = true;
                  }
                });

                const tribesParams = {
                  uid: this.props?.user?.id,
                  timestamp: new Date().toISOString(),
                  country: this.props.region,
                  contentTitle: this.state.episode
                    ? this.state.episode.titleEN
                    : this.state.item.titleEN,
                  contentId: this.state.episode
                    ? this.state.episode.contentId
                    : this.state.item.contentId,
                };

                if (
                  duration < 1200 &&
                  Math.floor(currentTime) === Math.floor(duration * 0.5) &&
                  !titlePlayViewed
                ) {
                  logCustomEvent({
                    name: "title_play_viewed",
                    params: baseParams,
                  });

                  logContentViewed({
                    params: tribesParams,
                  });

                  titlePlayViewed = true;
                } else if (
                  duration >= 1200 &&
                  Math.floor(currentTime) === 600 &&
                  !titlePlayViewed
                ) {
                  logCustomEvent({
                    name: "title_play_viewed",
                    params: baseParams,
                  });

                  logContentViewed({
                    params: tribesParams,
                  });                

                  titlePlayViewed = true;
                }
                if (
                  currentTimeRouded % 60 === 0 &&
                  currentTimeRouded !== this.state.minutesCounter
                ) {
                  this.setState({ minutesCounter: currentTimeRouded });
                  let data = {
                    type: "movie",
                  };
                  if (
                    Env.env === "glitch" &&
                    this.props.match.path.includes("short")
                  ) {
                    data.type = "short";
                  }
                  if (this.props.match.params.episode !== undefined) {
                    data.type = "serie";
                  }
                  if (this.props.match.params.chapter !== undefined) {
                    data.type = "audio";
                  }
                  data.time = currentTime;
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "minutePass",
                      minute: currentTime,
                      duration: duration,
                      userID: this.state.uid,
                      assetID: this.props.match.params.id,
                    },
                  });
                }
              });
            },
            () => {
              console.log("Error while loading source");
            }
          );
      });
    } catch (e) {
      console.log("error on load player ... ", e);
      toast.error("Something went wrong");
    }
  }

  destroyPlayer() {
    if (this.state.player != null) {
      this.state.player.destroy();
      this.setState({
        ...this.state,
        player: null,
      });
    }
  }

  onGoBack = () => {
    var linkGoBack = "/movie/" + this.props.match.params.id;
    if (this.props.match.path.includes("short")) {
      linkGoBack = "/short/" + this.props.match.params.id;
    }
    if (this.props.match.params.episode !== undefined) {
      linkGoBack = "/serie/" + this.props.match.params.id;
    }
    if (this.props.match.params.chapter !== undefined) {
      linkGoBack = "/audio/" + this.props.match.params.id;
    }
    this.props.history.push(linkGoBack);
  };

  render() {
    return (
      <div className="player-container">
        <>
          <button onClick={this.onGoBack} className="goBack">
            <img alt="back" src={GoBackButton} />
          </button>
          <div id="player"></div>
          {this.state.continueWatching ? (
            <ContinueWatching
              actualItem={this.state.item}
              setOpen={(pState) =>
                this.setState({ ...this.state, continueWatching: pState })
              }
              id={this.props.match.params.id}
              episode={this.state.episode}
            />
          ) : (
            <React.Fragment />
          )}
        </>
      </div>
    );
  }
}

export default withContext(withLanguage(PlayerComponent));
