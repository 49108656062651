import { useState } from "react";
import Input from "../../Input";
import Button from "../../Button";
import { validate, validateLogin } from "./utils";
import isEmpty from "lodash/isEmpty";
import { useFormik } from "formik";
import Env from "../../../../.env.js";

function FormFlow(props) {
  const {
    disabled,
    language,
    final,
    labels,
    colorFlow,
    btnFlow,
    loginEnabled = false,
    finalLogin = false,
  } = props;
  const [showLoginForm, setShowLoginForm] = useState(false);

  const changeSet = (values) => {
    if (showLoginForm && loginEnabled) {
      finalLogin && finalLogin(values);
    } else {
      final(values);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      repeatPassword: "",
    },
    validate:
      showLoginForm && loginEnabled
        ? validateLogin(language)
        : validate(language),
    onSubmit: (values) => {
      changeSet(values);
    },
  });

  return (
    <>
      {showLoginForm && loginEnabled ? (
        <>
          <div className={`create ${colorFlow}`}>{labels?.loginTitle}</div>
          <div className={`join ${colorFlow}`}>{labels.join}</div>
          <form className="box-form" onSubmit={formik.handleSubmit}>
            <Input
              containerClassname="mb-3"
              className="w-full force-black"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              placeholder={labels.email}
            />
            <Input
              placeholder={labels.password}
              containerClassname="mb-3"
              className="w-full force-black"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && formik.errors.password}
            />
            <Button
              label={labels.confirm}
              className={`uppercase w-full ${btnFlow} ${
                Env.env === "myst" && "text-white"
              }`}
              type="submit"
              disabled={!isEmpty(formik.errors) || disabled || !formik.dirty}
            />
          </form>
          <span
            className={`${colorFlow}`}
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => setShowLoginForm(false)}
          >
            {labels?.register}{" "}
          </span>
        </>
      ) : (
        <>
          {" "}
          <div className={`create ${colorFlow}`}>{labels.createAccount}</div>
          <div className={`join ${colorFlow}`}>{labels.join}</div>
          <form className="box-form" onSubmit={formik.handleSubmit}>
            <Input
              containerClassname="mb-3"
              className="w-full force-black"
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              placeholder={labels.email}
            />
            <div className="password">
              <Input
                placeholder={labels.password}
                containerClassname="mb-3"
                className="force-black"
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password}
              />
              <Input
                placeholder={labels.repeatPassword}
                containerClassname="mb-3"
                className="force-black"
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.repeatPassword}
                error={
                  formik.touched.repeatPassword && formik.errors.repeatPassword
                }
              />
            </div>
            <Button
              label={labels.confirm}
              className={`uppercase w-full ${btnFlow} ${
                Env.env === "myst" && "text-white"
              }`}
              type="submit"
              disabled={!isEmpty(formik.errors) || disabled || !formik.dirty}
            />
          </form>
          {loginEnabled && (
            <span
              className={`${colorFlow}`}
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setShowLoginForm(true)}
            >
              {labels?.registered}{" "}
            </span>
          )}
        </>
      )}
    </>
  );
}

export default FormFlow;
