import React from "react";
import { withContext } from "../providers/UserContext";
import Env from './../.env.js';

function FAQQuestion({
  translate = () => {},
  id = "",
  ext = <React.Fragment />,
}) {
  return (
    <article className="lg:mx-4 mx-0 w-full lg:w-5/12 mb-10">
      <h3 className="fw-700 primary-color fw-500 fs-16 ls-3 my-3">
        {translate(`faqQ${id}`)}
      </h3>
      <p
        className={`text-selego fs-15 ${Env.env !== "selego" && "text-whites"}`}
        dangerouslySetInnerHTML={{
          __html: translate(`faqA${id}`),
        }}
      />
      {ext}
    </article>
  );
}

export default withContext(FAQQuestion);
