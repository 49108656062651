import { genericPost } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";

export const getUserToken = () => {
  const user = JSON.parse(localStorage.getItem("userAuth"));

  return user?.token;
};

const SubscriptionServices = {
  createStripeCheckoutSession: (params, token = false) => {
    const { price_id, coupon, trial_period_days } = params;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await genericPost(
          endpointsQuery.createStripeCheckoutSession,
          null,
          { price_id, coupon, trial_period_days },
          `${token || getUserToken()}`
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  cancelStripeSubscription: (payload = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await genericPost(
          `${endpointsQuery.cancelStripeSubscription}`,
          null,
          payload,
           `${getUserToken()}`
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
};
export default SubscriptionServices;
