export const PodcastIconSvg = (
  <svg
    fill="#fff"
    height="20px"
    width="20px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    enable-background="new 0 0 512 512"
  >
    <g>
      <path d="m256,11c-81,0-147,65.9-147,147v100.3c0,74.1 55.1,135.6 126.5,145.5v56.4h-43.4c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h127.7c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-43.4v-56.4c71.4-10 126.5-71.4 126.5-145.5v-100.3c0.1-81.1-65.9-147-146.9-147zm0,353.4c-42.7,0-79.6-25.3-96.4-61.8h45.1c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-54.8c0-1.2-0.1-33.3-0.1-33.3h54.8c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-54.8c0,0 0-32.1 0.1-33.3h54.8c11.3,0 20.4-9.1 20.4-20.4s-9.1-20.4-20.4-20.4h-45.1c16.8-36.4 53.7-61.8 96.4-61.8 42.7,0 79.6,25.3 96.4,61.8h-45.1c-11.3,0-20.4,9.1-20.4,20.4s9.1,20.4 20.4,20.4h54.8c0,1.2 0.1,33.3 0.1,33.3h-54.8c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h54.8c0,0 0,32.1-0.1,33.3h-54.8c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h45.1c-16.8,36.5-53.7,61.8-96.4,61.8z" />
    </g>
  </svg>
);
export const ShortIconSvg = (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.24237 3.97575L9.34904 2.87563L8.71773 5.76968C8.71565 5.77385 8.71773 5.7801 8.71564 5.78427C8.71356 5.79885 8.71148 5.81344 8.71148 5.82802C8.71148 5.84052 8.70939 5.85511 8.70939 5.86761C8.70939 5.88011 8.71148 5.89261 8.71356 5.90511C8.71564 5.9197 8.71773 5.93637 8.7219 5.95095C8.72398 5.95512 8.7219 5.95929 8.72398 5.96554C8.72606 5.9697 8.72815 5.97179 8.72815 5.97595C8.74065 6.01554 8.75732 6.05305 8.78023 6.08847C8.78232 6.09263 8.78649 6.0968 8.78857 6.10097C8.81149 6.13222 8.83649 6.15931 8.86566 6.18223C8.86983 6.18639 8.87399 6.18848 8.87816 6.19264C8.90942 6.21556 8.94275 6.23223 8.98026 6.24682C8.98859 6.25098 8.99692 6.25307 9.00526 6.25515C9.01568 6.25723 9.02609 6.26348 9.03651 6.26557C9.0636 6.27182 9.09277 6.2739 9.11985 6.2739C9.12194 6.2739 9.12402 6.2739 9.12402 6.2739H9.1261C9.15736 6.2739 9.18861 6.26973 9.21986 6.2614C9.22403 6.2614 9.2282 6.2614 9.23237 6.25932L17.727 3.98408C18.5042 3.77572 18.9667 2.97356 18.7584 2.19639L18.4583 1.07961C18.2687 0.375371 17.5937 -0.0684256 16.8894 0.00866572C16.8311 0.00658217 16.7727 0.0169999 16.7206 0.0378354C16.704 0.0420025 16.6873 0.0440861 16.6727 0.0482532L5.02568 3.16941C4.80274 3.22983 4.67147 3.45694 4.7319 3.67988C4.79024 3.90282 5.01943 4.03617 5.24237 3.97575ZM12.8348 1.9422L12.2577 4.58831L9.67616 5.28005L10.2533 2.63394L12.8348 1.9422ZM16.3227 1.00669L15.7455 3.65488L13.164 4.34662L13.7412 1.70051L16.3227 1.00669ZM17.6541 1.29838L17.9541 2.41517C18.0437 2.74853 17.8458 3.09232 17.5124 3.18191L16.654 3.4111L17.2103 0.856671C17.4186 0.912927 17.5937 1.07336 17.6541 1.29838Z"
      fill="currentColor"
    />
    <path
      d="M17.4603 7.04019H6.08829C5.14444 7.04019 4.78191 6.35679 4.33603 5.31501C4.00891 4.55035 3.63804 3.68359 2.73586 3.68359H1.48781C0.683562 3.68359 0.0293274 4.33783 0.0293274 5.14208V18.5414C0.0293274 19.3456 0.683562 19.9999 1.48781 19.9999H17.4582C18.2625 19.9999 18.9167 19.3456 18.9167 18.5414V8.49868C18.9167 7.69443 18.2646 7.04019 17.4603 7.04019ZM18.0833 8.50076V10.2822H16.081L14.8392 7.8757H17.4582C17.8041 7.87361 18.0833 8.15489 18.0833 8.50076ZM8.8615 10.2822L7.6197 7.8757H10.2929L11.5347 10.2822H8.8615ZM12.4723 10.2822L11.2305 7.8757H13.9037L15.1455 10.2822H12.4723ZM17.4582 19.1685H1.48781C1.14194 19.1685 0.862748 18.8873 0.862748 18.5435V7.4569V5.14416C0.862748 4.79829 1.14403 4.5191 1.48781 4.5191H2.73378C3.06923 4.5191 3.26925 4.94623 3.56928 5.64421C3.9714 6.58598 4.52355 7.87361 6.08829 7.87361H6.6821L7.9239 10.2801H2.87546C2.64627 10.2801 2.45875 10.4676 2.45875 10.6968C2.45875 10.926 2.64627 11.1135 2.87546 11.1135H18.0833V18.5414C18.0833 18.8873 17.8041 19.1685 17.4582 19.1685Z"
      fill="currentColor"
    />
    <path
      d="M14.1558 13.3145H4.3756C4.14641 13.3145 3.95889 13.502 3.95889 13.7312C3.95889 13.9604 4.14641 14.1479 4.3756 14.1479H14.1558C14.385 14.1479 14.5725 13.9604 14.5725 13.7312C14.5725 13.502 14.385 13.3145 14.1558 13.3145Z"
      fill="currentColor"
    />
    <path
      d="M14.1558 16.1895H4.3756C4.14641 16.1895 3.95889 16.377 3.95889 16.6062C3.95889 16.8354 4.14641 17.0229 4.3756 17.0229H14.1558C14.385 17.0229 14.5725 16.8354 14.5725 16.6062C14.5725 16.377 14.385 16.1895 14.1558 16.1895Z"
      fill="currentColor"
    />
  </svg>
);

export const MovieIconSvg = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M1.81334 0H20.0019C20.7914 0 21.434 0.64177 21.434 1.43029V15.3587C21.434 16.1472 20.7914 16.789 20.0019 16.789H11.3462V19.1239H16.0831C16.3243 19.1239 16.5217 19.321 16.5217 19.5619C16.5217 19.8029 16.3243 20 16.0831 20H5.73221C5.49098 20 5.29361 19.8029 5.29361 19.5619C5.29361 19.321 5.49098 19.1239 5.73221 19.1239H10.469V16.7868H1.81334C1.02387 16.7868 0.381329 16.145 0.381329 15.3565V1.43029C0.381329 0.64177 1.02387 0 1.81334 0ZM1.25852 15.3587C1.25852 15.6631 1.50633 15.9128 1.81334 15.9128H20.0019C20.309 15.9106 20.5568 15.6631 20.5568 15.3587V1.43029C20.5568 1.12364 20.309 0.876136 20.0019 0.876136H1.81115C1.50633 0.876136 1.25852 1.12364 1.25852 1.43029V15.3587Z"
        fill="currentColor"
      />
      <path
        d="M3.05898 13.4092H18.7563C18.9976 13.4092 19.1949 13.6063 19.1949 13.8472C19.1949 14.0882 18.9976 14.2853 18.7563 14.2853H3.05898C2.81775 14.2853 2.62038 14.0882 2.62038 13.8472C2.62038 13.6063 2.81556 13.4092 3.05898 13.4092Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="21.0526"
          height="20"
          fill="currentColor"
          transform="matrix(-1 0 0 1 21.434 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AudioIconSvg = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0693 0.372501C20.7365 0.0832759 20.2973 -0.0472015 19.8602 0.013688L8.88703 1.53592C8.11287 1.64248 7.57356 2.5319 7.57356 3.2604V16.5169C7.57356 16.5169 7.57356 16.5169 7.57356 16.5191C7.57791 17.6412 6.50147 18.7241 5.0684 19.0373C3.4396 19.3939 1.89562 18.6806 1.62379 17.4476C1.49767 16.8692 1.66946 16.2559 2.11308 15.7188C2.57845 15.1512 3.28086 14.7424 4.08764 14.5641C4.52909 14.4684 4.97054 14.4467 5.39894 14.5032C5.63597 14.5336 5.85561 14.3662 5.88605 14.1292C5.9165 13.89 5.74905 13.6725 5.51202 13.642C4.98359 13.5725 4.4421 13.5964 3.90062 13.716C2.90247 13.9356 2.02827 14.451 1.43895 15.1686C0.823533 15.9167 0.588673 16.7931 0.773516 17.6368C1.08666 19.0612 2.54366 20.0006 4.23986 20.0006C4.57041 20.0006 4.91182 19.9658 5.25324 19.8897C7.10602 19.4831 8.44558 18.0696 8.44124 16.5234C8.44124 16.5212 8.44124 16.5212 8.44124 16.5191V5.90691L20.7213 4.20201V14.6685C20.7257 15.7906 19.6492 16.8735 18.2162 17.1867C17.4094 17.3628 16.6004 17.2845 15.9393 16.9649C15.313 16.6604 14.8999 16.1755 14.7716 15.597C14.6454 15.0186 14.8172 14.4053 15.2609 13.8682C15.7262 13.3006 16.4286 12.8918 17.2354 12.7135C17.7399 12.6026 18.2597 12.5917 18.7402 12.683C18.9751 12.7287 19.2034 12.5721 19.2491 12.3373C19.2948 12.1024 19.1382 11.8741 18.9033 11.8284C18.3097 11.7153 17.6703 11.7284 17.0506 11.8632C16.0524 12.0828 15.1782 12.5982 14.5889 13.3159C13.9735 14.0639 13.7386 14.9403 13.9235 15.784C14.1083 16.6278 14.6889 17.3259 15.5609 17.7477C16.109 18.013 16.7331 18.15 17.3789 18.15C17.716 18.15 18.0596 18.1131 18.4032 18.037C20.2342 17.6347 21.5629 16.2472 21.589 14.7207C21.589 14.712 21.5912 14.7033 21.5912 14.6924V1.5207C21.5912 1.08143 21.402 0.661725 21.0693 0.372501ZM8.44341 5.02837V3.2604C8.44341 2.87767 8.73263 2.43404 9.00664 2.39707L19.9798 0.874838C20.169 0.848743 20.3538 0.903108 20.4973 1.02924C20.643 1.15536 20.7213 1.32933 20.7213 1.5207V3.32564L8.44341 5.02837Z"
      fill="currentColor"
    />
  </svg>
);
