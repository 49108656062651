import { AxiosError, AxiosResponse } from "axios";
import {
  SignInResponse,
  SignParams,
} from "../models/interfaces/AuthInterfaces";
import { genericGet, genericPost, genericPatch } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";
import { getBrandId } from "../utils/getAppVariantId";

const brandId = getBrandId();
const signUp = async (params: SignParams) => {
  const response = await genericPost(endpointsQuery.signUp, undefined, {
    ...params,
    brand_id: brandId,
  });
  return response;
};

const signIn = async (
  params: SignParams
): Promise<AxiosResponse<SignInResponse, AxiosError>> => {
  return await genericPost(endpointsQuery.signIn, undefined, {
    ...params,
    brand_id: brandId,
  });
};

const confirmEmail = async (confirmationToken: string) => {
  const response = await genericGet(
    endpointsQuery.confirmEmail.replace("{token}", confirmationToken)
  );
  return response.data;
};

const unlockPassword = async (unlockToken: string) => {
  const response = await genericGet(
    endpointsQuery.unlockAccount.replace("{token}", unlockToken)
  );
  return response.data;
};

const signOut = async (token: string) => {
  await genericPost(endpointsQuery.signOut, undefined, token);
};

const checkUserToken = async (token: string) => {
  return await genericGet(
    endpointsQuery.checkUserToken,
    undefined,
    null,
    token
  );
};

const refreshToken = async (refresh_token: string) => {
  return await genericPost(endpointsQuery.refreshToken, "", {}, refresh_token);
};

const sendUnlockEmail = async (
  email: string
): Promise<AxiosResponse<any, AxiosError>> => {
  return await genericPost(endpointsQuery.sendUnlockEmail, undefined, {
    user: { email, brand_id: brandId },
  });
};

const sendConfirmEmail = async (
  email: string
): Promise<AxiosResponse<any, AxiosError>> => {
  return await genericPost(endpointsQuery.sendConfirmEmail, undefined, {
    user: { email, brand_id: brandId },
  });
};

const getCountryPlans = async (countryCode: string) => {
  try {
    const response = await genericGet(
      endpointsQuery.availableCountry
        .replace("{countryCode}", countryCode)
        .replace("{brandId}", brandId.toString())
    );
    return response.data;
  } catch {
    console.log("Error on get if country is available");
    return false;
  }
};

const sendForgotPasswordEmail = async (
  email: string
): Promise<AxiosResponse<any, AxiosError>> => {
  return genericPost(endpointsQuery.sendResetPassword, undefined, {
    user: { email, brand_id: brandId },
  });
};

const resetPassword = async (
  resetToken: string,
  email: string,
  password: string,
  repeatPassword: string
) => {
  const response = await genericPatch(
    endpointsQuery.resetPassword.replace("{token}", resetToken),
    undefined,
    {
      user: {
        email,
        brand_id: brandId,
        password,
        password_confirmation: repeatPassword,
        reset_password_token: resetToken,
      },
    }
  );
  return response.data;
};

export const AuthServices = {
  signIn,
  signUp,
  confirmEmail,
  signOut,
  unlockPassword,
  sendUnlockEmail,
  sendConfirmEmail,
  getCountryPlans,
  sendForgotPasswordEmail,
  resetPassword,
  checkUserToken,
  refreshToken,
};
